import appleLogo from '../images/AppleApp.png';
import androidLogo from '../images/AndroidApp.png';

export default function PaymentType({title, height, width, image, textBlock, list, type}) {
    
    return(
        <>
            <div className="lg:max-w-md">
                <h2 className="text-center text-lg my-4 text-red-500 underline">{title}</h2>

                <img src={image} height={height} width={width} className="my-4 mx-auto" />

               <div className="mb-4 px-4">
                {
                        textBlock.map((text, index)=> {
                            return(
                                <p key={index} className={` text-center text-black`} >{text.text}</p>
                            )
                        })
                    }
               </div>
                
            
            <div className="text-center ">
                <ul className=" px-8 inline-block">
                        {
                            list.map((text, index)=> {
                                return(
                                    <li key={index}>
                                        <p className="mb-2">{text.text}</p>
                                    </li>
                                )
                                
                            })
                        }
                    </ul>
            </div>
                
               
                {
                    type === "CabPay" && (
                        <>
                        <div className="max-w-xs grid grid-cols-1 justify-between my-4 mx-auto space-y-3">
                                    <a href="https://cabpay.uk/faqs" target="_blank" className="text-center bg-gray-500 text-white rounded mx-8 px-8 py-2">
                                            FAQ's
                                    </a>
                                    <a href="https://cabpay.uk/help" target="_blank" className="text-center bg-gray-500 text-white rounded px-8 mx-8 py-2">
                                            Help
                                    </a>
                            </div>  
                            <h4 className="text-center">
                                Download Cabvision Driver App here:
                            </h4>
                            <div className="flex flex-wrap lg:flex-row justify-between max-w-xs mx-auto py-2 px-8">

                                <div >
                                    <h5 className="text-center my-2">Apple:</h5>
                                    <a href="https://apps.apple.com/gb/app/cabvision-driver/id977600137" target="_blank">
                                        <img src={appleLogo} height={120} width={120} className="mx-auto" />
                                    </a>
                                </div>
                                
                                <div>
                                    <h5 className="text-center my-2">Android:</h5>

                                    <a href="https://play.google.com/store/apps/details?id=com.cabvision.cabvisiondriver&hl=en_GB" target="_blank">
                                        <img src={androidLogo} height={120} width={120} className="mx-auto" />
                                    </a>
                                    

                                </div>
                               
                            
                            </div>
                        </>
                    )
                }
                {
                    type==="Ingenico2" && (
                        <>
                            <div className="flex flex-col lg:flex-row lg:justify-between max-w-xs lg:max-w-3xl px-4 mx-auto mt-4">
                                <a href="/_files/ugd/20c165_08260ba7bcc64fd49433b0f5d260863a.pdf" target="_blank" className="mb-4 lg:mb-0 bg-gray-500 text-center hover:bg-gray-700 text-white font-bold py-2 px-4 rounded-md">
                                    Cabvision Users Manual
                                </a>
                                <div className="w-6"></div>
                                <a href="https://www.youtube.com/channel/UCre34z4v-qF4UfHgtBzISKQ" target="_blank" className=" bg-gray-500 text-center hover:bg-gray-700 text-white font-bold py-2 px-4 rounded-md">
                                    Cabvision Instruction Videos
                                </a>
                            </div>
                        </>
                    )
                }
            </div>
        </>
    )
}