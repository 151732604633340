import twitter from "../images/twitter_small.webp";
import cabpay from "../images/cabpay.webp"
import { useLocation } from 'react-router-dom';

export default function Footer() {
    
    const { pathname } = useLocation();

    //console.log(pathname);


    return(
        <>  
            <div className="containerr flex flex-col mt-4">
                <div className="flex flex-wrap text-center my-4 justify-center">
                    <h2>CABVISION NETWORK LTD. IS AUTHORISED BY THE FINANCIAL CONDUCT AUTHORITY (FCA). REGISTRATION NUMBER 910574</h2>
                </div>
                <div className="flex flex-col items-center lg:flex-row lg:justify-between lg:px-64">
                    <a className="mb-4" href="https://twitter.com/CabvisionTaxi">
                        <span className="hidden">Twitter</span>
                        <img src={twitter} height={40} width={40} alt="Twitter Logo" />
                    </a>

                    {
                        pathname !== "/contact-us" && (
                            <a href="/contact-us" className="mb-4 bg-red-500 hover:bg-red-700 text-white font-bold py-2 px-4 rounded-md">
                                Contact Us
                            </a>
                        )
                    }
                    
                    {
                        pathname !== "/contact-us" && (
                            <a href="https://lostproperty.cabvision.com/" target="_blank" className="mb-4 bg-red-500 hover:bg-red-700 text-white font-bold py-2 px-4 rounded-md">
                                Lost Property
                            </a>
                        )
                    }
                    
                    <a className="mb-4" href="https://twitter.com/CabvisionTaxi">
                        <span className="hidden">CabPay</span>
                        <img src={cabpay} height={45} width={101} alt="CabPay Logo" />
                    </a>
                </div>
                <div className="flex flex-wrap justify-center">
                    <h3 className="text-center">&copy; {new Date().getFullYear()} Cabvision Network Ltd</h3>
                </div>
            </div>
            
        </>
    )
}