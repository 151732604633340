import { Link } from 'react-router-dom';

export default function HomeSquare({logo, heightLogo, widthLogo, title, mainImage, heightMainImage, widthMainImage, lineArr, hyperlink}) {
    
    return(
        <>  
            <div className="flex flex-col my-4">
                <img className="mx-auto" height={heightLogo} width={widthLogo} src={logo} alt="Cabvision Logo" />
                <h1 className="text-center my-4">{title}</h1>
                <img className="mx-auto" height={heightMainImage} width={widthMainImage} src={mainImage} alt="Explanatory Image"/>
                {lineArr.map((obj, index) => {
                    return (
                        <div key={index}>
                            <h2 className={`${obj.class ? obj.class : ""} text-center my-1`}>{obj.text}</h2>
                        </div>
                    )
                })}
                <Link className="text-center mt-2 text-red-500 underline" to={"/" + `${hyperlink}`} >More...</Link>
                {/* <a className="text-center mt-2 text-red-500 underline" href={hyperlink}>More...</a> */}
            </div>
           
        </>
    )
}