import QA from '../Components/QA'

export default function PrivacyPolicy(){
    return (
        <>
            <h1 className="text-red-500 text-xl text-center">Cabvision Privacy and Data Policy</h1>
            <div className="max-w-3xl mx-auto px-4">
            <QA 
                textArr={[
                    {
                        q: "",
                        a: ["This privacy policy sets out how Cabvision Network Ltd uses and protects any information that you give Cabvision when you use the cabvision.com website, cabvisiondriver.com website, or Cabvision Driver Android & iOS Applications."]
                    },
                    {
                        q: "",
                        a: ["Cabvision is committed to ensuring that your privacy is protected. Should we ask you to provide certain information by which you can be identified when using this website, then you can be assured that it will only be used in accordance with this privacy statement. Cabvision may change this policy from time to time by updating this page. You should check this page from time to time to ensure that you are happy with any changes. This policy is effective from 1st January 2016."]
                    },
                    {
                        q: "WHAT WE COLLECT",
                        a: ["We may collect the following information when you engage Cabvision services Name and address data. Contact information including email address and postal address. Payment account details."]
                    },
                    {
                        q: "WHAT WE DO WITH THE INFORMATION WE GATHER",
                        a: ["We require this information to understand your needs and provide you with a better service, and in particular for the following reasons: Internal record keeping. We may use the information to improve our products and services. We may periodically send promotional emails about new products, special offers or other information which we think you may find interesting using the email address which you have provided (providing you have have opted in to receive the information). From time to time, we may also use your information to contact you for matters relating to your contract with Cabvision. We may contact you by email, phone, or mail. We may use the information to customise the website according to your interests."]
                    },
                    {
                        q: "SECURITY",
                        a: ["We are committed to ensuring that your information is secure. In order to prevent unauthorised access or disclosure, we have put in place suitable physical, electronic and managerial procedures to safeguard and secure the information we collect online."]
                    },
                    {
                        q: "HOW WE USE COOKIES",
                        a: ["A cookie is a small file which asks permission to be placed on your computer hard drive. Once you agree, the file is added and the cookie helps analyse web traffic or lets you know when you visit a particular site. Cookies allow web applications to respond to you as an individual. The web application can tailor its operations to your needs, likes and dislikes by gathering and remembering information about your preferences. We use traffic log cookies to identify which pages are being used. This helps us analyse data about webpage traffic and improve our website in order to tailor it to customer needs. We only use this information for statistical analysis purposes and then the data is removed from the system. Overall, cookies help us provide you with a better website by enabling us to monitor which pages you find useful and which you do not. A cookie in no way gives us access to your computer or any information about you, other than the data you choose to share with us. You can choose to accept or decline cookies. Most web browsers automatically accept cookies, but you can usually modify your browser setting to decline cookies if you prefer. This may prevent you from taking full advantage of the website."]
                    },
                    {
                        q: "LINKS TO OTHER WEBSITES",
                        a: ["A cookie is a small file which asks permission to be placed on your computer hard drive. Once you agree, the file is added and the cookie helps analyse web traffic or lets you know when you visit a particular site. Cookies allow web applications to respond to you as an individual. The web application can tailor its operations to your needs, likes and dislikes by gathering and remembering information about your preferences. We use traffic log cookies to identify which pages are being used. This helps us analyse data about webpage traffic and improve our website in order to tailor it to customer needs. We only use this information for statistical analysis purposes and then the data is removed from the system. Overall, cookies help us provide you with a better website by enabling us to monitor which pages you find useful and which you do not. A cookie in no way gives us access to your computer or any information about you, other than the data you choose to share with us. You can choose to accept or decline cookies. Most web browsers automatically accept cookies, but you can usually modify your browser setting to decline cookies if you prefer. This may prevent you from taking full advantage of the website."]
                    },
                    {
                        q: "CONTROLLING YOUR PERSONAL INFORMATION",
                        a: ["You may choose to restrict the collection or use of your personal information in the following ways: Whenever you are asked to fill in a form on the website, look for the box that you can click to indicate that you do not want the information to be used by anybody for direct marketing purposes If you have previously agreed to us using your personal information for direct marketing purposes, you may change your mind at any time by writing to or emailing us at gdpr@cabvision.com We will not sell, distribute or lease your personal information to any third parties under any circumstances. You may request details of personal information which we hold about you under the Data Protection Act 1998. A small fee will be payable. If you would like a copy of the information held on you please write to Cabvision, Hemming House, Hemming Street, London E1 5BL. If you believe that any information we are holding on you is incorrect or incomplete, please write to or email us as soon as possible at the above address. We will promptly correct any information found to be incorrect."]
                    },
                ]}
            />
            </div>
            

        </>
    )
}