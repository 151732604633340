import Meter from "../Components/Meter";
import Analog from "../images/HaleAnalog.webp"
import Analog2 from "../images/Analog2.webp"
import MR from "../images/MR400.webp"
import U1 from "../images/UKAS1.webp"
import U2 from "../images/UKAS2.webp"

export default function TaxiMeters() {
    return (
        <>
             <h1 className="my-4 uppercase text-center text-red-500 text-xl">Meters</h1>

             <div className="flex flex-wrap lg:px-32 my-6 lg:my-24 justify-center lg:justify-between">
                    <Meter
                        title="HALE ANALOGUE METER"
                        image={Analog}
                        imageHeight={75}
                        imageWidth={216}
                        textArr={
                            [
                                {
                                    text: "Cabvision Price £135.00 inc.VAT per year*"
                                },
                                {
                                    text: "Special Offer for Cabvision Payment",
                                    class: "text-red-500"
                                },
                                {
                                    text: "Terminal Customers: £110 inc VAT per year*",
                                    class: "text-red-500"
                                }
                            ]
                        }
                    />
                    <Meter
                        title="HALE MICROTAX MAGIC CEY"
                        image={Analog2}
                        imageHeight={75}
                        imageWidth={216}
                        textArr={
                            [
                                {
                                    text: "£145.00 inc. VAT per year or"
                                },
                                {
                                    text: "Special Offer, £240.00 inc. VAT for 2 years",
                                    class: "text-red-500"
                                },
                                {
                                    text: "if paid with one payment*",
                                    class: "text-red-500"
                                }
                            ]
                        }
                    />
                    <Meter
                        title="CABVISION MR400 SIM"
                        image={MR}
                        imageHeight={75}
                        imageWidth={216}
                        cyg={true}
                        textArr={
                            [
                                {
                                    text: "£165.00 inc. VAT per year or"
                                },
                                {
                                    text: "Special Offer, £280.00 inc. VAT for 2 years",
                                    class: "text-red-500"
                                },
                                {
                                    text: "if paid with one payment*",
                                    class: "text-red-500"
                                }
                            ]
                        }
                    />

                    <h3 className="text-center mx-auto my-8 w-full">
                    Global Meters has been part of the Cabvision Network since 2014 and is one of only two testing centres, fully authorised to test and calibrate meters in the UK and Europe.
                    </h3>

                    <div className="flex justify-center mx-auto w-full">
                        <div>
                           
                            <img src={U1} height={51} width={39} className="mr-4" />
                             4232
                        </div>
                        
                        <div>
                           
                            <img src={U2} height={51} width={39} />
                             4232
                        </div>
                    </div>
             </div>
        </>
    )
}