import logo from './logo.svg';
import './App.css';
import Home from './Components/Home';
import {
  BrowserRouter as Router,
  Routes, 
  Route
} from "react-router-dom";
import Header from './Components/Header';
import Footer from './Components/Footer';
import CardPayments from './Pages/CardPayments';
import TaxiRental from './Pages/TaxiRental';
import TaxiMeters from './Pages/TaxiMeters';
import MoneyBag from './Pages/MoneyBag';
import Register from './Pages/Register';
import NotFoundPage from './Components/NotFoundPage';
import ContactUs from './Pages/ContactUs';
import PrivacyPolicy from './Pages/PrivacyPolicy';


function App() {
  return (
    <Router>
      <Header/>
        <Routes>
          <Route path="/" element={<Home/>} />
          <Route path="/home" element={<Home/>} />
          <Route path="/card-payments" element={<CardPayments/>} />    
          <Route path="/taxi-rental" element={<TaxiRental/>} />    
          <Route path="/taxi-meters" element={<TaxiMeters/>} />    
          <Route path="/moneybag" element={<MoneyBag/>} />    
          <Route path="/register" element={<Register/>} />    
          <Route path="/contact-us" element={<ContactUs/>} />
          <Route path="/privacy-policy" element={<PrivacyPolicy/>} />
          <Route path="*" element={<NotFoundPage/>} />
        </Routes>
      <Footer/>
    </Router>
  );
}

export default App;
