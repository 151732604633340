import React from 'react';
import { Link } from 'react-router-dom';
import Linker from '../Components/Linker';
import { useEffect } from 'react';

export default function ContactUs() {


    return (
        <>
             <h1 className="my-4 uppercase text-center text-red-500 text-xl">Contact Us</h1>
            
            <div className="max-w-md mx-auto containerr mt-6 lg:text-left flex flex-col sm:flex-row justify-between mb-4 space-y-3 sm:space-y-0">

                <a href="https://receipts.cabvision.com/" target="_blank" className="px-4 flex justify-center items-center text-center bg-red-500 hover:bg-red-700 text-white font-bold py-2 mx-2 rounded-md">
                    <span>Request Journey Receipt</span>
                </a>

                <a href="https://www.cabvisiondriver.com/" target="_blank" className="px-4 flex justify-center items-center text-center bg-red-500 hover:bg-red-700 text-white font-bold py-2 mx-2 rounded-md">
                    <span>Lost Property</span>
                </a>

            </div>

            <h2 className="my-4 uppercase text-center text-red-500 text-xl">24/7 UK phone support for drivers and passengers</h2>

            <div className="mx-6">
                <a href="tel:02076556970" className="my-4 max-w-lg px-4 flex items-center justify-center text-center bg-red-500 hover:bg-red-700 text-white font-bold py-2 mx-auto rounded-md">
                            <svg xmlns="http://www.w3.org/2000/svg" className="h-6 w-6" fill="none" viewBox="0 0 24 24" stroke="currentColor" strokeWidth="2">
                                <path strokeLinecap="round" strokeLinejoin="round" d="M16 3h5m0 0v5m0-5l-6 6M5 3a2 2 0 00-2 2v1c0 8.284 6.716 15 15 15h1a2 2 0 002-2v-3.28a1 1 0 00-.684-.948l-4.493-1.498a1 1 0 00-1.21.502l-1.13 2.257a11.042 11.042 0 01-5.516-5.517l2.257-1.128a1 1 0 00.502-1.21L9.228 3.683A1 1 0 008.279 3H5z" />
                            </svg>
                            <span className="ml-2">020 7655 6970</span>
                </a>
            </div>
           

            <h3 className="my-4 uppercase text-center text-red-500 text-lg">Cabvision</h3>
            <h4 className="text-center">171 Lukin Street</h4>
            <h4 className="text-center">London</h4>
            <h4 className="text-center">E1 0BN</h4>

            <div className="flex flex-col items-center my-4 ">
                <svg xmlns="http://www.w3.org/2000/svg" className="mb-2 h-6 w-6 mr-2" fill="none" viewBox="0 0 24 24" stroke="currentColor" strokeWidth={2}>
                    <path strokeLinecap="round" strokeLinejoin="round" d="M12 8v4l3 3m6-3a9 9 0 11-18 0 9 9 0 0118 0z" />
                </svg>
               
                    <h4 className="mb-2">Monday to Friday 08.30 - 17.30</h4>
                    <h4 className="mb-2 text-center"><span className="text-red-500">OPEN</span> for Registration, General Support and Hardware Maintenance</h4>
             
            </div>
            
            <div className="flex flex-col items-center">
                <svg xmlns="http://www.w3.org/2000/svg" className="h-6 w-6" fill="none" viewBox="0 0 24 24" stroke="currentColor" strokeWidth={2}>
                    <path strokeLinecap="round" strokeLinejoin="round" d="M17.657 16.657L13.414 20.9a1.998 1.998 0 01-2.827 0l-4.244-4.243a8 8 0 1111.314 0z" />
                    <path strokeLinecap="round" strokeLinejoin="round" d="M15 11a3 3 0 11-6 0 3 3 0 016 0z" />
                </svg>
                Find us
            </div>
            
            <iframe className="mx-auto my-8" width="600" height="450" loading="lazy" allowFullScreen src="https://www.google.com/maps/embed/v1/view?zoom=17&center=51.5120%2C-0.0518&key=AIzaSyBDKz_lxwD6_44uEw_TI0mSPubtx7nqCp0"></iframe>

            <div className="mx-4">
                <Link to="/privacy-policy" className="max-w-sm mx-auto px-4 flex justify-center items-center text-center bg-red-500 hover:bg-red-700 text-white font-bold py-2 rounded-md">
                    <span>Privacy Policy</span>
                </Link>
            </div>    
            

        </>
    )
}