import HomeSquare from "./HomeSquare";
import mainLogo from '../images/clogo.webp';
import cardTerminal from '../images/cardTerminal.webp'
import threeCab from '../images/3Cab.webp'
import meter from '../images/Meter.webp'


export default function Home () {

    return (
        <>  
            <div className="flex flex-wrap lg:px-32 my-6 lg:my-24 justify-center lg:justify-between">
                <HomeSquare
                    logo={mainLogo}
                    heightLogo={13}
                    widthLogo={67}
                    title="CARD PAYMENTS"
                    mainImage={cardTerminal}
                    heightMainImage={185}
                    widthMainImage={75}
                    lineArr={
                        [
                            {
                                text: "Free Next Day Payments",
                                class: "mt-4"
                            },
                            {
                                text: "24/7 UK Phone Transaction Support"
                            }
                        ]
                    }
                    hyperlink="card-payments"
                />
                <HomeSquare
                    logo={mainLogo}
                    heightLogo={13}
                    widthLogo={67}
                    title="TAXI RENTAL"
                    mainImage={threeCab}
                    heightMainImage={166}
                    widthMainImage={288}
                    lineArr={
                        [
                            {
                                text: "LEVC TXE",
                                class: "mt-4"
                            },
                            {
                                text: "Vito Euro 6"
                            },
                            {
                                text: "LTI TX4 EU6"
                            },
                            {
                                text: "Vito Euro 5"
                            },
                            {
                                text: "Please enquire for pricing.",
                                class: "mt-4"
                            }
                        ]
                    }
                    hyperlink="taxi-rental"
                />
                <HomeSquare
                    logo={mainLogo}
                    heightLogo={13}
                    widthLogo={67}
                    title="TAXI METERS"
                    mainImage={meter}
                    heightMainImage={74}
                    widthMainImage={209}
                    lineArr={
                        [
                            {
                                text: "Hale & Cygnus",
                                class: "mt-4"
                            },
                            {
                                text: "Testing & Calibration"
                            }
                        ]
                    }
                    hyperlink="taxi-meters"
                />
            </div>
        </>
    )

}