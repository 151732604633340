export default function Register() {
    return (
        <>
            <h1 className="text-center text-xl mb-4 mt-64">Signup with Cabvision</h1>
            <p className="text-center max-w-md mx-auto mb-4">These links are for London Licensed Taxi Drivers only.  If you wish to register with Cabvision to use the Cabvision Ingenico Payment Solution, please choose from the options below.</p>
            <div className="flex mx-auto justify-center flex-col lg:flex-row px-4 max-w-xs lg:max-w-xl">
                <a href="https://cabpay.uk/register-ingenico-fleet" target="_blank" className="text-center lg:mr-4 mb-4 lg:mb-0 bg-red-500 hover:bg-red-700 text-white font-bold py-2 px-4 rounded-md">
                I drive a taxi rented from a garage
                </a>
                <a href="/register-taxi-owner" target="_blank" className="text-center bg-red-500 hover:bg-red-700 text-white font-bold py-2 px-4 rounded-md">
                I own my Taxi
                </a>
            </div>
            <div className="spacer mb-64"></div>

        </>
    )
}