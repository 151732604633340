export default function Bullet({textArr}) {
    return(
        <>
            <div className="text-center lg:text-left">
                <ul className="list-disc px-8 inline-block">
                        {
                            textArr.map((text, index)=> {
                                return(
                                    <li key={index}>
                                        <p className="mb-2">{text.text}</p>
                                    </li>
                                )
                                
                            })
                        }
                    </ul>
            </div>
        </>
    )
}