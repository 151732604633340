export default function Meter({title, image, imageHeight, imageWidth, textArr, cyg}){
    return(
        <>
            <div className="flex flex-col">
                <h2 className="text-center mb-4">{title}</h2>
                <img src={image} height={imageHeight} width={imageWidth} className="mx-auto mb-4"/>
                {cyg === true && <small className="text-center mb-2">by Cygnus</small>}
                {
                    textArr.map((item, index) => {
                        return(
                            <p className={`${item.class} text-center mb-2`}>{item.text}</p>
                        )
                    })
                }
            </div>
        </>
    )
}