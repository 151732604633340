import React from 'react';
import Bullet from '../Components/Bullet'
import CabPrice from '../Components/CabPrice'
import threeCab from '../images/3Cab.webp'
import { Formik, Field, Form, ErrorMessage } from 'formik';
import * as Yup from 'yup';
import { Debug }  from '../Components/Debug';
import taxitypes from '../Data/taxiTypes';
import axios from 'axios';

export default function TaxiRental() {

    const Fieldset = ({ name, label, secondLabel, x, noLabel, textArea, ...rest }) => (
        <React.Fragment>
            {/* {
                !noLabel && <label className={`block text-md font-medium text-gray-700 ${ secondLabel ? "pb-2 mb-2":""}`} htmlFor={name}>{label}</label>
            } */}
        

            {!x && 
                <Field as={`${ textArea ? "textarea" : "input"}`} rows="4" cols="50" className="py-2 px-3 border mt-1 focus:ring-indigo-500 focus:border-indigo-500 block w-full shadow-sm sm:text-sm border-gray-300 rounded-md" id={name} name={name} {...rest} />
            }

            
            <ErrorMessage name={name}>
                { msg => <div className="text-red-500 max-w-sm">{msg}</div> }
            </ErrorMessage>

        </React.Fragment>
    );
    
    const sendForm = (values) => {
        console.log(values);

        axios.post(`/api/contactform`, {
            fullName : values.full_name,
            email : values.email,
            postCode : values.home_postcode,
            message : values.message_box,
            phoneNo : values.phone_number
          })
          .then(function (response) {
            console.log(response);
          })
          .catch(function (error) {
            console.log(error);
          });

    }



    return (
        <>
             <h1 className="my-4 uppercase text-center text-red-500 text-xl">Taxi Rental</h1>
             <div className="flex flex-col items-center max-w-xl mx-auto">
                <img 
                    className="mx-auto mb-2"
                    height={195}
                    width={339}
                    src={threeCab}
                />
                <CabPrice cabName="LEVC TXE" cabPrice="340"/>
                <CabPrice cabName="Euro 6 Vito & LTI TX4" cabPrice="275"/>
                <CabPrice cabName="Vito Euro 5" cabPrice="240"/>

                <div className="spacer mb-4"></div>

                <Bullet 
                    textArr={
                        [
                            {
                                text: "Our rental fleet of London Licensed Taxis is mainly comprised of LEVC TXE, Mercedes Vito Euro 6, and a limited amount of TX4 and EU5 Vito."
                            },
                            {
                                text: "All our London Licensed Taxis are black (black cabs) and have no adverts or advertising screens."
                            },
                            {
                                text: "Each black cab is fitted with a Cabvision payment system and supplied with one of our own taxi meters."
                            },
                            {
                                text: "Support of our licensed taxi fleet is carried out directly by Mercedes-Benz as well as KPM Automotive who are LEVC maintenance agents and Mercedes-Benz specialists, having introduced Vito Taxis to the market in 2008."
                            },
                            {
                                text: "24/7 Mercedes-Benz Assist road-side recovery on all Vitos"
                            }
                        ]
                    }
                />

                <small>Terms & Conditions Apply</small>

                <p className="my-4 text-center">To register your interest in one of our London Taxis, please fill in the form using BLOCK CAPITAL LETTERS.</p>

                <Formik
                             initialValues={{
                                licensed: false
                              }}
                              validationSchema={Yup.object().shape({
                                full_name: Yup.string().required('You must enter a Full Name'),
                                phone_number: Yup.number().min(0).required('You must enter a number'),
                                email: Yup.string().email('Invalid email address').required('Email Address is Required'),
                                home_postcode: Yup.string().required('You must enter a home postcode'),
                                // vehicle_type: Yup.string().required('You must select a vehicle type'),
                                message_box: Yup.string().max(800, "Please shorten your entry"),
                                licensed: Yup.boolean()
                                .required("You must be licensed for more than 12 months to proceed")
                                .oneOf([true], "You must be licensed for more than 12 months to proceed")
                            })}
                              onSubmit={values => {
                                  //console.log(values)
                                  sendForm(values);
                            //    submittedFormObject.current = values;
			                //    refineItemsDesc(values);
                            //    refineObject(values);
                            //    submitFormHandler();
                            }}
                            >
                            
                            { ({isSubmitting, handleReset, errors, values, touched}) => (
                            <Form className="max-w-sm sm:max-w-lg">
                              <div className="shadow overflow-hidden sm:rounded-md">
                                <div className="px-4 py-5 bg-white sm:p-6">
                                    <div 
                                     className="flex flex-col max-w-xs sm:max-w-lg px-4"
                                    >

                                    <div className="field mb-2">
                                        <Fieldset
                                        name="full_name"
                                        type="text"
                                        noLabel={true}
                                        placeholder="Full Name"
                                        />
                                    </div>
                                    {!touched.full_name && errors.full_name && (<><div className="text-red-500" >You must enter a Full Name</div></>)}

                                    <div className="mb-2">
                                            <Fieldset
                                            name="phone_number"
                                            type="number"
                                            noLabel={true}
                                            placeholder="Phone Number"
                                            />
                                    </div>
                                    {!touched.phone_number && errors.phone_number && (<><div className="text-red-500" >You must enter a Phone Number</div></>)}

                                    <div className="field mb-2 ">
                                        <Fieldset
                                        name="email"
                                        type="email"
                                        label="Email"
                                        noLabel={true}
                                        placeholder="example@email.com"
                                        />
                                    </div>
                                    {!touched.email && errors.email && (<><div className="text-red-500" >You must enter a valid email</div></>)}
                                    

                                    <div className="field mb-2">
                                        <Fieldset
                                        name="home_postcode"
                                        type="text"
                                       
                                        placeholder="Home Postcode"
                                        noLabel={true}
                                        />
                                    </div>
                                    {!touched.home_postcode && errors.home_postcode && (<><div className="text-red-500" >You must enter a valid home postcode</div></>)}

                                    <Field as="select" name="vehicle_type" id="vehicle_type" className="h-full border border-gray-300 rounded-md mb-2">
                                            {taxitypes.map( (taxi, index) =>
                                                <option 
                                                key={index} 
                                                >{taxi.taxi}</option>
                                            )}
                                    </Field>

                                    <div className="field mb-2">
                                        <Fieldset
                                        name="message_box"
                                        type="text"
                                        textArea={true}
                                        label="Message"
                                        placeholder="Message"
                                        />
                                    </div>
                                                
                                    <label className="my-2">
                                        <Field className="mr-2" type="checkbox" name="licensed" />
                                        I confirm I have been licensed as a Hackney Carriage Driver for more than 12 months.
                                    </label>

                                    { errors.licensed && (<><div className="text-red-500 max-w-sm">You must be licensed for more than 12 months to proceed</div></>)}

                                        {/* <Debug /> */}

                                        </div>
                                    </div>
                                    <div className="px-4 py-3 bg-gray-50 text-right sm:px-6">
                                        <button type="submit" className="inline-flex justify-center py-2 px-4 border border-transparent shadow-sm text-sm font-medium rounded-md text-white bg-red-600 hover:bg-red-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-red-500">
                                        Submit
                                        </button>
                                    </div>

                                </div>
                              </Form>
                            )
                            }
                          </Formik>

             </div>
        </>
    )
}