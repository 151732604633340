export default function QA({textArr}){

  

    return (
        <>
            {
                textArr.map((item, index) => {
                    //const inside = item.a;
                    return (
                        <div key={index}>
                            <h2 className="mb-4 text-xl">{item.q}</h2>

                            {
                                item.a.length <= 1 && (
                                    <p className="mb-2">{item.a}</p>
                                )
                            }

                            {/* {console.log(item.a.length)} */}

                            {
                                item.a.length > 1 && (
                                    item.a.map((item, index)=>{
                                        return (
                                            <div key={index}>
                                                <p className="mb-2">{item}</p>
                                            </div>
                                        )
                                    })
                                
                                )
                            }

                            { item.pic ? <img src={item.src} height={item.height} width={item.width} /> : '' }
                        </div>
                    )
                })
            }
        </>
    )
}