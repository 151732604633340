import { Link } from 'react-router-dom';
import cabpay from '../images/cabpay.webp';

export default function NotFoundPage() {
    return (
        <>
            <div className="my-24">
                <h1 className="text-center text-xl">Unfortunately you have reached a page that doesn't exist</h1>
                <Link to="/">
                    <img
                        className="mx-auto my-8"
                        src={cabpay}
                        width={200}
                        height={200}
                    />
                    <h2 className="text-center text-lg">Click to take a trip back to the home page</h2>
                </Link>
            </div>
        </>
    )
}