import React, { useState, Fragment } from 'react';
import { Disclosure, Menu, Transition } from '@headlessui/react'
import {  BellIcon, MenuIcon, XIcon } from '@heroicons/react/outline'
import mainLogo from '../images/clogo.webp';
import { Link, useLocation } from 'react-router-dom';
import Linker from './Linker';

export default function Header() {

    const [ menu, setMenu ] = useState(false);

    const { pathname } = useLocation();

    //console.log(pathname);


    return(
        <>  

            <nav className="mx-auto containerr mt-4">

                <div className="flex items-center justify-between">

                    <Link to="/">
                        <span className="hidden">Cabvision</span>
                        <img className="" width={230} height={45} src={mainLogo} alt="Main Logo" /> 
                    </Link>

                    <svg onClick={()=>setMenu(!menu)} xmlns="http://www.w3.org/2000/svg" className="lg:hidden h-8 border border-black rounded w-8" fill="none" viewBox="0 0 24 24" stroke="currentColor" strokeWidth={2}>
                        <path strokeLinecap="round" strokeLinejoin="round" d="M4 6h16M4 12h16M4 18h16" />
                    </svg>
                    
                    <ul className="hidden lg:block">
                        <li className="flex flex-wrap">

                            <Linker rpath="/" title="Home" desktop={true} />
                            
                            <Linker rpath="card-payments" title="Card Payments" desktop={true} />
                            
                            <Linker rpath="taxi-rental" title="Taxi Rental" desktop={true}/>
                            
                            <Linker rpath="taxi-meters" title="Taxi Meters" desktop={true}/>
                            
                            <Linker rpath="moneybag" title="Moneybag" desktop={true}/>
                            
                            <Linker rpath="register" title="Register" desktop={true}/>

                            <Linker rpath="receipts" title="Receipts" desktop={true} external={true} url="https://receipts.cabvision.com"/>

                            <Linker rpath="lost-property" title="Lost Property" desktop={true} external={true} url="https://lostproperty.cabvision.com"/>

                            <Linker rpath="contact-us" title="Contact Us" desktop={true}/>
                        </li>
                    </ul>


                </div>

                <div className={`${ menu ? 'block' : 'hidden'} lg:hidden`}>
                    <ul className="mt-2">
                        <li className="flex flex-col">

                            <div className="my-4 uppercase" onClick={()=>setMenu(!menu)}>
                                <Linker rpath="/" title="Home"/>
                            </div>

                            <div className="my-4 uppercase" onClick={()=>setMenu(!menu)}>
                                <Linker rpath="card-payments" title="Card Payments" />
                            </div>   
                            
                            <div className="my-4 uppercase" onClick={()=>setMenu(!menu)}>
                                <Linker rpath="taxi-rental" title="Taxi Rental"/>
                            </div>

                            <div className="my-4 uppercase" onClick={()=>setMenu(!menu)}>
                                <Linker rpath="taxi-meters" title="Taxi Meters"/>
                            </div>

                            <div className="my-4 uppercase" onClick={()=>setMenu(!menu)}>
                                <Linker rpath="moneybag" title="Moneybag"/>
                            </div>

                            <div className="my-4 uppercase" onClick={()=>setMenu(!menu)}>
                                <Linker rpath="register" title="Register"/>
                            </div>
                            
                            <div className="my-4 uppercase" onClick={()=>setMenu(!menu)}>
                                <Linker rpath="receipts" title="Receipts" external={true} url="https://receipts.cabvision.com"/>
                            </div>

                            <div className="my-4 uppercase" onClick={()=>setMenu(!menu)}>
                                <Linker rpath="lost-property" title="Lost Property" external={true} url="https://lostproperty.cabvision.com"/>
                            </div>

                            <div className="my-4 uppercase" onClick={()=>setMenu(!menu)}>
                                <Linker rpath="contact-us" title="Contact Us" />
                            </div>

                            <a href="https://www.cabvisiondriver.com/" target="_blank" className="hidden lg:block w-32 bg-red-500 hover:bg-red-700 text-white font-bold py-2 px-4 rounded-md">
                                Driver Login
                            </a>
                        </li>
                    </ul>
                </div>
                

            </nav>

            {   
                pathname !== "/contact-us" && (
                    <div className="max-w-md mx-auto containerr mt-6 lg:text-left flex flex-col sm:flex-row sm:max-w-none justify-between mb-4 space-y-3 sm:space-y-0">

                    <a href="https://www.cabvisiondriver.com/" target="_blank" className="px-4 flex justify-center items-center text-center bg-red-500 hover:bg-red-700 text-white font-bold py-2 mx-2 rounded-md">
                        <span>Driver Login</span>
                    </a>

                    <a href="tel:02076556970" className="px-4 flex items-center justify-center text-center bg-red-500 hover:bg-red-700 text-white font-bold py-2 mx-2 rounded-md">
                        <svg xmlns="http://www.w3.org/2000/svg" className="h-6 w-6" fill="none" viewBox="0 0 24 24" stroke="currentColor" strokeWidth="2">
                            <path strokeLinecap="round" strokeLinejoin="round" d="M16 3h5m0 0v5m0-5l-6 6M5 3a2 2 0 00-2 2v1c0 8.284 6.716 15 15 15h1a2 2 0 002-2v-3.28a1 1 0 00-.684-.948l-4.493-1.498a1 1 0 00-1.21.502l-1.13 2.257a11.042 11.042 0 01-5.516-5.517l2.257-1.128a1 1 0 00.502-1.21L9.228 3.683A1 1 0 008.279 3H5z" />
                        </svg>
                        <span className="ml-2">020 7655 6970</span>
                    </a>

                    <Link to="contact-us" className="px-4 flex justify-center items-center text-center bg-red-500 hover:bg-red-700 text-white font-bold py-2 mx-2 rounded-md">
                        <span>Contact Us</span>
                    </Link>

                    </div>
                )

            }
            
        </>
    )
}