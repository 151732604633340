import PaymentType from "../Components/PaymentType";
import cl from "../images/CabPayLogo2.webp"
import cardTerminal from '../images/cardTerminal.webp'

export default function CardPayments() {

    return (
        <>
            <h1 className="my-4 uppercase text-center text-red-500 text-xl">Card Payments</h1>
            <div className="flex flex-col lg:flex-row flex-wrap lg:mx-64 lg:justify-between ">
                
                <PaymentType 
                    title="CabPay Mobile Device"
                    height={54}
                    width={126}
                    image={cl}
                    textBlock={
                        [
                            {
                                text : "Transaction cost: 1.99% + 10p"
                            },
                            {
                                text : "No Contract."
                            },
                            {
                                text : "No Support Fees."
                            },
                            {
                                text : "No Hidden Fees"
                            }
                        ]
                    }
                    list={
                        [
                            {
                                text: "​Free next day payments 6 days a week"
                            },
                            {
                                text: "24/7 UK Phone support for drivers"
                            },
                            {
                                text: "Automated tip prompt on every transaction"
                            },
                            {
                                text: "Free professional installation"
                            },
                            {
                                text: "Accepts all major Credit Card inc. AmEx, Contactless, Apple/Android Pay"
                            },
                            {
                                text: "No additional charges for foreign cards"
                            },
                            {
                                text: "Cabvision Driver App for real time transaction data"
                            },
                            {
                                text: "Personalised up to date financial statements on driver portal"
                            }
                        ]
                    }
                    type="CabPay"
                />

                <PaymentType 
                    title="Ingenico Option 2"
                    height={106}
                    width={42}
                    image={cardTerminal}
                    textBlock={
                        [
                            {
                                text : "Transaction cost: 2.95% +16p per transaction."
                            },
                            {
                                text : "Fully integrated with Taxi faremeter, all equipment supplied by Cabvision."
                            },
                            {
                                text : "This option is offered on a 24 month contract with a support fee of £16.25 inc vat for the month, if the amount processed in the calendar month is below £900. The support fee is only payable if you process less than £900 in that calendar month. "
                            }
                        ]
                    }
                    list={
                        [
                            {
                                text: "​Free next day payments 6 days a week"
                            },
                            {
                                text: "24/7 UK phone transaction support for drivers"
                            },
                            {
                                text: "Cabvision Driver hardware support 7 days a week"
                            },
                            {
                                text: "Automated tip prompt on every transaction"
                            },
                            {
                                text: "Free professional installation"
                            },
                            {
                                text: "Accepts all major Credit Card inc. AmEx, Contactless, Apple/Android Pay"
                            },
                            {
                                text: "No additional charges for foreign cards"
                            },
                            {
                                text: "Cabvision Driver App for real time transaction data"
                            },
                            {
                                text: "Personalised up to date financial statements on driver portal"
                            }
                        ]
                    }
                    type="Ingenico2"
                />

            </div>
        </>
    )
}