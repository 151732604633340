import { Link, useLocation } from 'react-router-dom';

export default function Linker({rpath, title, desktop, external, url}) {

    const { pathname } = useLocation();

    //console.log(pathname, rpath);

    return(
        <>
            {
                desktop && !external && (
                    // <a className={` uppercase lg:mr-2 xl:mr-4`} >{name}</a>
                    <Link to={`${rpath}`} className={`${pathname === "/" + rpath || pathname === rpath ? 'active text-red-600' : ''} mx-2 uppercase`}>{title}</Link>
                )
            }

            {
                desktop && external && (
                    // <a className={` uppercase lg:mr-2 xl:mr-4`} >{name}</a>
                    <a href={url} className={`${pathname === "/" + rpath || pathname === rpath ? 'active text-red-600' : ''} mx-2 uppercase`}>{title}</a>
                )
            }

            {
                !desktop && !external && (
                    <Link to={`${rpath}`} className={`${pathname === "/" + rpath || pathname === rpath ? 'active text-red-600' : ''} my-4 uppercase`}>{title}</Link>
                )
            }

            {
                !desktop && external && (
                    <a href={url} className={`${pathname === "/" + rpath || pathname === rpath ? 'active text-red-600' : ''} my-4 uppercase`}>{title}</a>
                )
            }
            
        </>
    )
} 

// ${pathname === "/" + name.toLowerCase() ? 'active text-red-600' : '' }
// // ${pathname === "/" + name.toLowerCase() ? 'active text-red-600' : '' }
// href={`${pathname === "/" ? '/' : "/" + name.toLowerCase()}`}

// href={`/${name.toLowerCase()}`}